const errors = {
  notfound: {
    preheader: 'ERROR',
    header: 'Page Not Found',
    message: 'This page does not exist or has been moved. Check the address or go back.',
    button: 'Go Back',
    url: '/home',
    support: false
  },
  noauth: {
    preheader: '401 ERROR',
    header: 'You are not authorized',
    message: 'Looks like you need to login.',
    button: 'Return to Login',
    url: '/'
  },
  noCID: {
    preheader: 'Account Issue',
    header: 'Account Registration Error',
    message: 'Please contact your administrator for more information.',
    button: '',
    url: ''
  }
};

export default errors;