<template>
  <page-error
  :data="error"
  :image-url="require('../assets/img/errors/bg-image.png')"
  >
    <template #action>
      <p>
        <Button color="primary" @clicked="$router.go(-1)">{{error.button}}</Button>
      </p>
      <p v-show="error.support">
        <router-link to="/">Contact Support</router-link>
      </p>
    </template>
  </page-error>
</template>

<script>
import { PageError, Button } from '@cendyn/cendyn-frontend-kit';
import errors from '../data/errors';

export default {
  name: 'Error',
  components: {
    PageError,
    Button
  },
  data() {
    return {
      buttonText: 'Go Back'
    };
  },
  computed: {
    error() {
      const type = this.$route.meta.type;
      return errors[type];
    }
  }
};
</script>
